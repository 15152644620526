import React, { useState } from 'react';
import { Alert as ReactAlert }  from 'react-bootstrap';
import AlertContainer from './alert.style';
import tw from 'twin.macro';

interface ModalProps {
    handleClose:any,
    handleShow: any,
    title: string,
    body:any,
    show: boolean
}

const ReactAlertContainer = tw(ReactAlert)`fixed mr-5 mt-5 top-0 right-0 z-50`


const Alert = (props) => {

    //const [show, setShow] = useState(false);

    //const handleClose = () => setShow(false);
    //const handleShow = () => setShow(true)

  return (
    <ReactAlertContainer {...props}>
        {props.children}
    </ReactAlertContainer>    
  );
}

export default Alert;