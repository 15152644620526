import React from 'react';
import Layout from '@components/Layout';
import Landing from '../sections/Landing';
import Testimonial from '@sections/Testimonial';
import Features from '@sections/Features';
import Header from '@components/Header';
import Footer from '@components/Footer';
import SEO from '@components/Seo';
import Banner from '@sections/Banner';
import Claim from '@sections/Claim';
import Pricing from '@sections/Pricing';
import ContactUsForm from '@sections/ContactUs';
import CookieBanner from '@components/Cookies/CookieBanner';

const IndexPage = () => (
  <Layout>
    <SEO lang="es" title="Cocina para ti" keywords={[`solidaridad`, `barrio`, `madrid`, `restaurantes`, `para llevar`, `banco de alimentos`]} />
    <Header />
    <Claim/>
    <Banner/>
    <Features/>
    <Testimonial />
    <Pricing />
    <ContactUsForm />
    <Footer />
    <CookieBanner/>
  </Layout>
);

export default IndexPage;
