import React from "react";

import tw from 'twin.macro';
import { SectionContainer, Row, Col } from '@components/common/Layout';
import { SectionLink } from 'react-scroll-section';
import Button from '@components/common/Button';
import markdownRenderer from '@components/MarkdownRenderer';


import AppScreenImage from '@assets/images/app/local.svg';

import BannerSectionWrapper from './claim.style';
import web from '@assets/data/web';



const Place = tw.h1`font-bold text-highlight font-serif text-4xl lg:text-6xl`;
const Header = tw.h1`font-extrabold text-primarydark text-3xl lg:text-4xl`;
const Text = tw.div`pt-3 text-xl w-3/4 md:w-full`;
const BannerContainer= tw.div`sm:w-full max-w-6xl mx-auto pt-20 sm:pt-0 md:pt-10 bg-custompink`;


const Claim = () => {

  return (
    <BannerContainer>
    <BannerSectionWrapper>
      <SectionContainer id="claim" fullWidthSM>
        <Row Vcenter={true}>
          <Col sm={12} xs={12} md={7}>
            <Place>Chamartin</Place>
            <Header>{web.claim}</Header>
            <Text>
              {web.bannerText}
            </Text>
            
          </Col>

          <Col sm={12} xs={12} md={5} pr={0}>
            <div className="app-screen-image-wrapper">
              <img src={AppScreenImage} alt={web.name} />
            </div>
          </Col>
        </Row>
        <Row Hcenter>


        <SectionLink section="signup">
          {({ onClick, isSelected }) => (                            
            <Button className="banner-btn one" onClick={onClick}>
                empezar ahora
            </Button>
          )}
        </SectionLink>
        </Row>
      </SectionContainer>
    </BannerSectionWrapper>
    </BannerContainer>
  );
};

export default Claim;
