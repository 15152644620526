import styled from 'styled-components';

const ContactUsSectionWrapper = styled.div`
    position: relative;
    .icon {
        color: black;
    }

    .signup-btn {
        margin-top: 1rem;
        background-color: ${props => props.theme.colors.primaryDark} !important;
        color: white;
        font-weight: 800;
        font-famly: 'Poppins';
        &:hover {
            background-color: ${props => props.theme.colors.primaryLight} !important;
            color: ${props => props.theme.colors.primaryDark};
          }        
    }

    a:not([href]) {
        color: ${props => props.theme.colors.primaryDark} !important;
        text-decoration: underline !important;
        cursor: pointer;

        &:hover {
            color: ${props => props.theme.colors.secondaryColor} !important;
        }
    }

    input.input-error{
        border-bottom-color: #da4e4e !important;

        &:hover{
            border-bottom-color: #da4e4e !important;
        }

        &:focus{
            border-bottom-color: #da4e4e !important;
        }        
    }

    input.input {

        & :hover {
            border-bottom-color: ${props => props.theme.colors.primaryDark};
        }

        & :focus {
            border-bottom-color: ${props => props.theme.colors.primaryDark};
        }        
    }

    .validation-error {
        color: #da4e4e;
        display: flex;
        align-items: center;

        svg {
            margin-right: 10px;
            width: 20px;
            height: 20px;
        }

        span {
            flex-grow:1;
        }
    }
    @media only screen and (max-width: 1280px) {

    }
    @media only screen and (max-width: 912px) {

    }
    @media only screen and (max-width: 480px) {

    } 
`

export default ContactUsSectionWrapper;