import React, { useState } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import Moment from 'moment';

import firebase from 'firebase';
import { useFirebase } from '@hooks/UseFirebase';
import { Cookies } from 'react-cookie-consent';

//import { Switch } from '@rebass/forms';
import { SectionHeading, Subheading as SubheadingBase } from '@components/styled/Headings';
import EmailIllustrationSrc from '@assets/images/app/abiertos.svg';
import { SectionContainer } from '@components/common/Layout';
import Modal from '@components/Modal';
import { ToggleSwitch } from '@components/form/ToggleSwitch';
import ContactUsSectionWrapper from './contactus.style';
import Button from '@components/common/Button';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { FaExclamationTriangle } from 'react-icons/fa';
import { Link } from 'gatsby';
import { UseContentfulPrivacy } from '@hooks/UseContentfulPrivacy';

import Alert from '@components/Alert';
import AlertContent from '@components/Alert/AlertContent';
import SuccessImg from '@assets/images/app/alert/success.svg';
import FailureImg from '@assets/images/app/alert/error.svg';


const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-5 md:py-10`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto hidden md:block`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-4 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;
const TextLegal = tw.span`text-xs text-justify leading-none py-4 text-black`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-extrabold text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`

const CheckBoxContainer = tw.div`flex mt-5 items-center`;
const CheckBoxInput = tw.div`mr-3`;
const CheckBoxLabel = tw.div`leading-tight text-left`;

const signupValidationSchema = Yup.object().shape({                 
  email: Yup.string()
      .email('Ups esto no parece un correo electrónico')
      .required('Indícanos un correo para ayudarte a dar alta tu restaurante y tu carta'),
  name: Yup.string()
      .min(2, 'Por favor indícanos tu nombre, al menos 3 caracteres')
      .required('Por favor indícanos tu nombre'),
  terms: Yup.boolean()
      .oneOf([true], 'Es necesario aceptar los términos y condiciones'),
});


const ContactUs = ({
  subheading = "",
  heading = <>Abre tu restaurante online</>,
  description = "Apúntate y en unos días te enviaremos el acceso para poner en marcha tu negocio.",
  submitButtonText = "empezar ahora",
  formAction = "#",
  formMethod = "post",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  //-- modal condiciones
  const { nodes } = UseContentfulPrivacy();
  const [modalShow, setModalShow] = React.useState(false);
  const [alertSuccessShow, setAlertSuccessShow] = React.useState(false);
  const [alertFailureShow, setAlertFailureShow] = React.useState(false);
  const [username, setUsername] = React.useState('');


  const firebaseApp = useFirebase();
  const analytics = Cookies.get('yolocal-gdpr-google-analytics');
  if (firebaseApp && analytics){
    //    console.log('analytics apoyo local -- habilitando analytics');
        firebase.analytics();
  }
  const initialValues = {
    email: '',
    phone: '',
    name: '',
    terms: false,
    date: Moment(Date.now()).format("DD/MM/YYYY HH:mm:ssZ")
  };

  const logEvent = (event: string, params?: any) => { 
    const analytics = Cookies.get('chamartincocinaparati-gdpr-google-analytics')
    if (firebaseApp && analytics){
      firebase.analytics().logEvent(event, params);
    }    
  }
  const handleSubmit = (fields, resetForm) => {  
    setUsername(fields.name);

    firebaseApp.database().ref('chamartin-landing').push(fields).then(() => {  
      setAlertSuccessShow(true);
      logEvent('signup_business');
      logEvent('generate_lead');      
    }).catch(() => {
     console.log('danger', 'Your message could not be sent');
     setAlertFailureShow(true);
     logEvent('exception',{description:'business_form'});
    });
  }

  return (
    <SectionContainer id="signup" >
    <ContactUsSectionWrapper>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Formik
                initialValues={initialValues}
                validationSchema={signupValidationSchema}
                onSubmit={ (fields, resetForm) => {
                  handleSubmit(fields, resetForm);
                }}
                render={({ setFieldValue,values, dirty, handleSubmit, handleChange, errors, status, touched }) => (            
            <Form onSubmit={handleSubmit}>
              <Input type="text" name="name" placeholder="Indícanos tu nombre" value={values.name} onChange={handleChange} className={`input ${errors.name?'input-error':''}`}/>
              {errors.name && touched.name ? (             
              <div className="validation-error"><FaExclamationTriangle/><span>{errors.name}</span></div>     ) : null}

                         
              <Input type="email" name="email" placeholder="Dirección de correo electrónico"  value={values.email} onChange={handleChange} className={`input ${errors.email?'input-error':''}`}/>
              {errors.email && touched.email ? (             
              <div className="validation-error"><FaExclamationTriangle/><span>{errors.email}</span></div>     ) : null}

              <Input type="text" name="phone" placeholder="Teléfono de contacto"  value={values.phone} onChange={handleChange} className="input"/>

              
              <CheckBoxContainer>
              <CheckBoxInput><ToggleSwitch small={true} id="terms" text={["Sí","No"]} name="terms"  defaultChecked={false} checked={values.terms} onChange={() => setFieldValue('terms', !values.terms)}/></CheckBoxInput>
              <CheckBoxLabel><label htmlFor="terms">He leído y acepto la <a onClick={() => setModalShow(true)}><b>Política de Privacidad</b></a></label></CheckBoxLabel>
              </CheckBoxContainer>
              {errors.terms && touched.terms ? (             
              <div className="validation-error"><FaExclamationTriangle/><span>{errors.terms}</span></div>     ) : null}

              <Button type="submit" className="signup-btn">{submitButtonText}</Button>
              <TextLegal>
                Tus datos van a estar seguros porque cumplimos con el RPGD o Reglamento General de Protección de de Datos. Te dejamos esta información que debes saber:<br/>
                a) Responsable del tratamiento de estos datos: <b>Cocina para ti</b><br/>
                b) Finalidad: Vas a dejarnos tus datos para que te avisemos de novedades y solo haremos eso :) y la legitimación es tu consentimiento al rellenar el formulario<br/>
                c) Destinatarios: la recogida de datos la hace Firebase de Google y el envío de novedades Mailchimp, y ambos cumplen también la RPGD<br/>
                d) Derechos: tendrás derecho, entre otros, a acceder, rectificar, limitar y suprimir tus datos, como se explica en nuestra <a onClick={() => setModalShow(true)}><b>Política de Privacidad</b></a>
              </TextLegal>              
            </Form>
                )}
                />            
          </TextContent>
        </TextColumn>
      </TwoColumn>


      <Modal  
            size="lg"
            centered
            title="Política de privacidad" 
            body={nodes[0].content?.json}
            onHide={() => setModalShow(false)}
            show={modalShow}/>

    <Alert 
    variant="success"
    show={alertSuccessShow}
    onClose={() => setAlertSuccessShow(false)} dismissible title="Muchas gracias!!">
      <AlertContent imageSrc={SuccessImg} text={`Bienvenido ${username} muy pronto te llamaremos para empezar a hacer grandes cosas en el barrio`}/>
    </Alert> 

    <Alert 
    variant="danger"
    show={alertFailureShow}
    onClose={() => setAlertFailureShow(false)} dismissible title="Vaya, ha ocurrido un problema">
      <AlertContent imageSrc={FailureImg} text={`Lo sentimos ${username} ha habido un problema con el registro de tus datos, puedes ponerte en contacto con nosotros en este correo restaurantes@cocinaparati.com`}/>
    </Alert>  

    </ContactUsSectionWrapper>
    </SectionContainer>
  );
};

export default ContactUs;