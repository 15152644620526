import styled from 'styled-components';

const TestimonialSectionWrapper = styled.div`
    position: relative;
    .icon {
        color: black
    }

    .carousel-btn {
        ::before {
            content: none;
        }
        svg {
            color: ${props => props.theme.colors.primaryDark} !important;
        }
    }

    @media only screen and (max-width: 1280px) {

    }
    @media only screen and (max-width: 912px) {

    }
    @media only screen and (max-width: 480px) {

    } 
`

export default TestimonialSectionWrapper;