import React from 'react';

import { SectionContainer, Container, Row, Col } from '@components/common/Layout';
import SectionTitle from '@components/common/SectionTitle';
import Button from '@components/common/Button';
import { SectionLink } from 'react-scroll-section';
import { IoMdCheckmark, IoMdClose } from 'react-icons/io';
import Fade from 'react-reveal/Fade';

import particle1 from '@assets/images/app/particle/14.png';
import particle2 from '@assets/images/app/particle/15.png';

//import data from '@assets/data/pricing';

import PricingSectionWrapper from './pricing.style';
import rocketThumb from "@assets/images/app/rocket.png";
import { FaDesktop } from 'react-icons/fa';

/*
                <SectionLink section="signup">
                {({ onClick, isSelected }) => (                            
                  <Button className="pricing-btn" onClick={onClick}>
                    <FaDesktop className="icon-left" />
                    Apúntate
                  </Button>
                )}
                </SectionLink>       
*/              
const Pricing = () => {
  return (
    <PricingSectionWrapper id="oferta">
      <img className="section__particle one" src={particle1} alt="img" />
      <img className="section__particle two" src={particle2} alt="img" />
      <Container>
        <SectionTitle UniWidth="65%">
          <h2>
            <span>Tenemos un planazo</span>
          </h2>
        </SectionTitle>
        <Fade right>
          <Row Hcenter={true}>
              <Col xs={12} sm={10} lg={10}>
                <div className="pricing-block">
                  <div className="thumb-block">
                      <img src={rocketThumb} alt="plan gratuito" />
                      <div className="price-block">
                        <h1>0€</h1>
                        <p>plan gratuito!!</p>
                      </div>                      
                  </div>                  

                  <p>Envíanos tu factura de gas y de luz y comienza a ahorrar todos los meses. Si no mejoramos tus condiciones te regalamos la suscripción a la plataforma durante un año.</p>
                  {
                    /*
                
                  <div className="details-block">
                      <span ><IoMdCheckmark /> Publica toda la información de tu negocio</span>
                      <span ><IoMdCheckmark /> Recibe pedidos de tus vecinos</span>
                      <span ><IoMdCheckmark /> Forma parte del sello solidario</span>
                      <span ><IoMdCheckmark /> Conoce mejor a tus clientes</span>
                      <span ><IoMdCheckmark /> Ofrece catering a las empresas de tu alrededor</span>
                  </div>
                  */
                  }

               
                  
                </div>
              </Col>
          </Row>
        </Fade>
      </Container>
    </PricingSectionWrapper>
  );
};

export default Pricing;
