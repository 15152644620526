import React from "react";

import tw from 'twin.macro';
import { SectionContainer, Row, Col } from '@components/common/Layout';
import SectionTitle from '@components/common/SectionTitle';
import Button from '@components/common/Button';
import Particle from '@components/common/Particle';
import VintageBox from '@components/common/VintageBox';
import TextRich from "@components/TextRich";
import { SectionLink } from 'react-scroll-section';

import { FaDesktop } from "react-icons/fa";

import AppScreenImage from '@assets/images/app/app-circular.svg';
import AppScreenThumb1 from '@assets/images/app-screen-thumb-1.png';
import AppScreenThumb2 from '@assets/images/app-screen-thumb-2.png';

import BannerSectionWrapper from "./banner.style";
import { UseContentfulWeb, ContentfulWeb } from "@hooks/UseContentfulWeb";


const ButtonContainer = tw.div`flex md:block items-center justify-center `;

const Banner = () => {
  const contentful: ContentfulWeb = UseContentfulWeb();

  return (
    <BannerSectionWrapper>
      <Particle />
      <SectionContainer id="home" fullWidthSM>
        <Row Vcenter={true}>
          <Col sm={12} xs={12} md={7}>
            <TextRich json={contentful?.bannerText?.json}/>

            <ButtonContainer>
              <VintageBox right={true} vintageOne={true}>
                <SectionLink section="signup">
                  {({ onClick, isSelected }) => (                            
                    <Button className="banner-btn one" onClick={onClick}>
                      <FaDesktop className="icon-left" />
                      Apúntate aquí
                    </Button>
                  )}
                  </SectionLink>              
              </VintageBox>
            </ButtonContainer>
            
            
          </Col>

          <Col sm={12} xs={12} md={5}>
            <div className="app-screen-image-wrapper">
              <img src={contentful.bannerImage.image.src} alt="cocina para ti" />
            </div>
          </Col>
        </Row>
      </SectionContainer>
    </BannerSectionWrapper>
  );
};

export default Banner;
