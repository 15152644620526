import React from 'react';
// import { Box, Image, Flex } from 'rebass/styled-components';
import { SectionContainer, Row, Col, Container } from "@components/common/Layout";
import SectionTitle from "@components/common/SectionTitle";
import { StaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
// import ReactMarkdown from 'react-markdown';
// import Fade from 'react-reveal/Fade';
import Section from '@components/Section';
// import Triangle from '@components/Triangle';
// import markdownRenderer from '@components/MarkdownRenderer';
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"



import FeaturesSectionWrapper from "./features.style";


/*
const ProfilePicture = styled(Image)`
  border-radius: 50%;
  transition: all 0.25s ease-out;

  &:hover {
    border-radius: 20%;
  }
`;
*/


const Bold = ({ children }) => <span className="bold">{children}</span>
const Text = ({ children }) => <p className="align-center">{children}</p>


const Features = () => {

  
  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    },
  }
  
  //documentToReactComponents(node.bodyRichText.json, options)
  
  return (  
  <FeaturesSectionWrapper>
    <SectionContainer id="features" >
    <StaticQuery
      query={graphql`
        query FeaturesQuery {
          contentfulFeatures {
            name
            item_id {
              icon {
                image: resize(width: 48, height: 48, quality: 100) {
                  src
                }
              }
              name
              description {
                json
              }                
            }            
          }
        }
      `}
      render={(data) => {
        const { name, item_id } = data.contentfulFeatures;
        return (
          <Container>
            <SectionTitle UniWidth="65%">
              <h2>
                <span>{name}</span>
              </h2>
            </SectionTitle>
            <Fade top>
            <Row>
              {item_id.map((feature) => {
                const content = documentToReactComponents(feature?.description?.json, options);
                return(
                <Col xs={12} sm={6} md={4} key={`feature-${feature.name}`}>
                  <div className="fetures-block">
                    <div className="features-icon">
                      <img 
                        src={feature?.icon?.image.src}
                        alt={feature?.name}/>
                    </div>
                    <h3>{feature?.name}</h3>
                    <span>
                     {content}
                    </span>
                  </div>
                </Col>
                );
                }
              )}
            </Row>   
            </Fade>            
          </Container>
        );
      }}
    />
    </SectionContainer>
  </FeaturesSectionWrapper>
  
  );
}

export default Features;
