import React, { useState } from 'react'; 

import tw from "twin.macro";
import ToggleSwitchWrapper from "./toggleswitch.style";

const Checkbox = tw.input`absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer`;
const Label = tw.label`text-xs text-gray-700`;
const Container =tw.div`relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in`;
/*
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`
*/

interface ToggleSwitchProps {
    id: string,
    text: Array<string>,
    name: string,
    onChange?: any,
    defaultChecked?: boolean,
    small?: boolean,
    checked?: boolean,
    disabled?: boolean
  };

export const ToggleSwitch = (props:ToggleSwitchProps) => {

    const [checked, setChecked] = useState(props.defaultChecked);
    
    const onChange = (e) => {
        //setChecked(e.target.checked);

        if (typeof props.onChange === "function") props.onChange();
    }
    
    return (
        <>
            <ToggleSwitchWrapper/>
            <div className={"toggle-switch" + (props.small ? " small-switch" : "")}>
                <input
                type="checkbox"
                className="toggle-switch-checkbox"
                name={props.name}
                id={props.id}
                checked={props.checked}
                value={`${props.checked}`}
                onChange={onChange}
                disabled={props.disabled}          
                />
                {props.id ? (
                <label className="toggle-switch-label" htmlFor={props.id}>
                    <span
                    className={
                        props.disabled
                        ? "toggle-switch-inner toggle-switch-disabled"
                        : "toggle-switch-inner"
                    }
                    data-yes={props.text[0]}
                    data-no={props.text[1]}
                    />
                    <span
                    className={
                        props.disabled
                        ? "toggle-switch-switch toggle-switch-disabled"
                        : "toggle-switch-switch"
                    }
                    />
                </label>
                ) : null}
            </div>
        </>
    );
}

