import React from 'react';
import { Link } from 'gatsby';
import { SectionLink } from 'react-scroll-section';
import tw from 'twin.macro';

import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaEnvelope,
  FaPinterestP,
  FaInstagram
} from 'react-icons/fa';


import Button from '@components/common/Button';
import ParticleComponent from '@components/common/Particle';
import Modal from '@components/Modal';

import { Container, Row, Col } from '@components/common/Layout';
import LogoColor from '@components/Logo/logoColor.svg';
import LogoMadrid from '@assets/images/app/footer/footer-logo-madrid.svg';
import LogoCH from '@assets/images/app/footer/footer-logo-CH.svg';
import LinkAnimated from '@components/LinkAnimated';

import FooterBGTwo from '@assets/images/app/footer/footer-particle-two.png';
import { UseContentfulPrivacy } from '@hooks/UseContentfulPrivacy';
import { UseContentfulCookies } from '@hooks/UseContentfulCookies';
import { UseContentfulWeb } from '@hooks/UseContentfulWeb';

import FotterWrapper from './footer.style';
import Alert from '../Alert/index';

const ImgLogo = tw.img`h-12 w-12`;

const Footer = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const [content, setContent] = React.useState('');

  const cookies = UseContentfulCookies();
  const privacy = UseContentfulPrivacy();
  const web = UseContentfulWeb();

  const openModal = (data:any) =>{
    setTitle(data.titleHeading);
    setContent(data.content.json);
    setModalShow(true);
  }

  return (
    <FotterWrapper>
      <img src={FooterBGTwo} alt="img" className="section__particle one" />
      <Container>
        <Row>
          <Col xs={12} sm={6} lg={3}>
            <div className="footer-widgets first">
              <Link to="#" className="footer-logo">
                <img src={LogoColor} alt={web.name} />
              </Link>
            </div>
          </Col>
          <Col xs={12} sm={6} lg={2}>
            <div className="footer-widgets">
              <h3 className="widget-title">{web.name}</h3>
              <ul className="widget-catagory">
                <li>
                  <SectionLink section="features">
                    {({ onClick, isSelected }) => (                            
                    <a 
                      onClick={onClick}
                      tabIndex={0}> Características
                    </a>
                    )}
                  </SectionLink>
                </li>
                <li>
                <SectionLink section="testimonials">
                  {({ onClick, isSelected }) => (                            
                  <a   
                    onClick={onClick}
                    tabIndex={1}> Testimonios
                  </a>
                  )}
                </SectionLink>
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={12} sm={6} lg={3}>
            <div className="footer-widgets">
              <h3 className="widget-title">Ayuda</h3>
              <ul className="widget-catagory">
                <li>
                  <a onClick={() => openModal(privacy.nodes[0])}>Política de privacidad</a> 
                </li>
                <li>
                  <a onClick={() => openModal(cookies.nodes[0])}>Política de cookies</a>
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={12} sm={6} lg={2}>
            <div className="footer-widgets social-media">
              <h3 className="widget-title">Síguenos</h3>
              <ul className="social">
                <li>
                  <a href="https://www.facebook.com/Cocina-para-ti-106170794456352/?ti=as" target="_blank">
                    <FaFacebookF />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/cocina_ti" target="_blank">
                    <FaTwitter />
                  </a>
                </li>
                <li>
                  <a href="https://instagram.com/madrid.cocinaparati?igshid=1c22y0yjfsa9u" target="_blank">
                    <FaInstagram />
                  </a>
                </li>
              </ul>
              {
              // <p className="copyright-text">
                
                //&#169; 2020 powered by <a href="https://www.idenac.com" target="_blank"><u><b>idenac</b></u></a> 
                
              //</p>
              }
            </div>
          </Col>
          <Col xs={12} sm={6} lg={2} >
              <div className="d-flex flex-column">

                <span>promovido por: </span>
                <div className="d-flex flex-row mx-auto">
                  <span className="mx-2"><ImgLogo src={LogoMadrid}/></span>
                  <span className="mx-2"><ImgLogo src={LogoCH}/></span>
                  {
                  //&#169; 2020 powered by <a href="https://www.idenac.com" target="_blank"><u><b>idenac</b></u></a> 
                  }
                </div>
              </div>
          </Col>          
        </Row>
      </Container>
      
      <Modal  
            size="lg"
            centered
            title={title}
            body={content}
            onHide={() => setModalShow(false)}
            show={modalShow}/>


    </FotterWrapper>
  );
};

export default Footer;
