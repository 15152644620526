import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Slider from 'react-slick';
import styled from 'styled-components';
import tw from 'twin.macro';
import { css } from 'styled-components/macro'; //eslint-disable-line
import { SectionHeading as HeadingTitle } from '@components/styled/Headings';
import TextRich from '@components/TextRich';
import Button from '@components/common/Button';
import Section from '@components/Section';
import ArrowLeftIcon from '@assets/images/app/arrow-left-2-icon.svg';
import ArrowRightIcon from '@assets/images/app/arrow-right-2-icon.svg';
import AppScreenImage from '@assets/images/app/app-circular.svg';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import TestimonialSectionWrapper from './testimonial.style';

/*
import QuotesLeftIcon from '@assets/images/app/quotes-l.svg';
import QuotesRightIcon from '@assets/images/app/quotes-r.svg';
import ArrowLeftIcon from '@assets/images/app/arrow-left-2-icon.svg';
import ArrowRightIcon from '@assets/images/app/arrow-right-2-icon.svg';
import SvgDecoratorBlob1 from '@assets/images/app/svg-decorator-blob-4.svg';
import SvgDecoratorBlob2 from '@assets/images/app/svg-decorator-blob-5.svg';
*/
import "slick-carousel/slick/slick.css";





const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-0`;
const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const TestimonialSliderContainer = tw.div`sm:mt-3 lg:mt-12`;
const TestimonialSlider = styled(Slider)``;
const Testimonial = tw.div`flex! flex-col items-center md:items-stretch md:flex-row md:justify-center outline-none`;
const ImageContainer = styled.div`
  ${tw`md:mx-3 lg:mx-6 w-2/3 md:w-4/12 rounded flex items-center max-w-xs md:max-w-none`}
  img {
    ${tw`rounded`}
  }
`;
const TextContainer = tw.div`md:mx-3 lg:mx-6 md:w-6/12 py-4 flex flex-col justify-between`;
const QuoteContainer = tw.div`relative p-6 md:p-8 lg:p-10 mt-4 md:mt-0`;
const Quote = tw.blockquote`text-center md:text-left font-medium text-xl lg:text-2xl xl:text-3xl`;
const CustomerInfo = tw.div`px-5 lg:px-10 text-center md:text-left mt-4 md:mt-0`;
const CustomerName = tw.h5`font-bold text-lg lg:text-xl xl:text-2xl`;
const CustomerTitle = tw.p`font-medium text-sm`;

/*
  button {
    ${tw`text-secondary-500 hover:text-primary-500 focus:outline-none transition duration-300 transform hover:scale-125 transform -translate-y-2/3 md:translate-y-0`}
    svg {
      ${tw`w-8`}
    }
  */
const SliderControlButtonContainer = styled.div`
  ${tw`top-0 flex items-center md:items-center z-0 h-full `}
  ${tw`absolute`}
  ${props => props.side === 'right'?tw`right-0`:tw`left-0`}
    svg {
      ${tw`w-16 h-16`}
    }
  }
`;


const NextArrow = ({ currentSlide, slideCount, ...props }) => (
  <SliderControlButtonContainer tw="right-0" side="right">
    <Button className="carousel-btn">
        <FaChevronRight tw="text-black" {...props}/>
    </Button>    
  </SliderControlButtonContainer>
);
const PreviousArrow = ({ currentSlide, slideCount, ...props }) => (
  <SliderControlButtonContainer tw="left-0" side="left">
    <Button className="carousel-btn">
      <FaChevronLeft tw="text-black" {...props}/>
    </Button>
  </SliderControlButtonContainer>
);


export default () => {
  return (
    <Section.Container id="testimonials">
    <TestimonialSectionWrapper>
      <Content>
        <HeadingInfoContainer>
          <HeadingTitle>Estos restaurantes ya cocinan para ti</HeadingTitle>
          <HeadingDescription></HeadingDescription>
        </HeadingInfoContainer>

          <StaticQuery
            query={graphql`
              query TestimonialQuery {
                allContentfulTestimonial {
                  nodes {
                      title
                      address
                      shortDescription {
                        json
                      }                      
                      image {
                        image: resize(width: 400, height: 400, quality: 100,cropFocus:CENTER) {
                          src
                        }
                      }
                  }
                }
              }
            `}
            render={(data) => {
              const {nodes} = data.allContentfulTestimonial;
              return (
                <TestimonialSliderContainer>
                <TestimonialSlider nextArrow={<NextArrow />} prevArrow={<PreviousArrow />}>
                  {nodes.map((testimonial, index) => (
                    <Testimonial key={index}>
                      <ImageContainer>
                        <img src={testimonial?.image?.image?.src} alt={testimonial.title} />
                      </ImageContainer>
                      <TextContainer>
                        <QuoteContainer>
                          <Quote><TextRich json={testimonial?.shortDescription?.json}/></Quote>
                        </QuoteContainer>
                        <CustomerInfo>
                          <CustomerName>{testimonial?.title}</CustomerName>
                          <CustomerTitle>{testimonial?.address}</CustomerTitle>
                        </CustomerInfo>
                      </TextContainer>
                    </Testimonial>
                  ))}
              </TestimonialSlider>
            </TestimonialSliderContainer>            
              );
            }}
            />              

      </Content>
    </TestimonialSectionWrapper>
    </Section.Container>
  );
};
