import styled from "styled-components";

const FeaturesSectionWrapper = styled.section`
   padding: 70px 0 px 0;
   h2 {
       margin: 0 0 10px 0;
   }
    .fetures-block{

        .features-icon {
            position: relative;
            display: inline-block;
            padding: 15px; 
            border-radius: 5px;
            margin-bottom: 0px;

            &:before {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                border-radius: 5px;
                background: ${props => props.theme.colors.primaryLight};
                content: " ";
                opacity: 0.08;
            }

            img {
                display: block;
                margin: 0;
                height: 48px;
                width: 48px;
            }
        }
        h3{
            font-size: 22px;
            color: ${props => props.theme.colors.primaryDark};
            margin-bottom: 0px;
            font-weight: 600;
            line-height: 30px;
            font-family: 'Barlow Condensed';
        }
        p{
            margin: 0 0 10px 0;
            font-weight: 300;
            text-align: justify;
        }

        &:hover{
            .features-icon {
                border-color: #DC7DCE; 
            }
        }
    }
    @media only screen and (max-width: 760px) {
        padding: 70px 0 0px 0;
    }
    @media only screen and (max-width: 568px) {
        .fetures-block{
            text-align: center;
        }
    }
`;

export default FeaturesSectionWrapper;